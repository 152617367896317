<template>
	<b-modal id="deploy-dispatch" :title="title" ok-title="Proceed" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="px-2">


			<!-- Source -->
			<b-form-group label="Source" label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
				{{ sourceCompany }}
				&nbsp;
				<span v-if="selDispatch.fromInactiveNode === 'true'">
					<b-badge variant="secondary" class="badgeFont">Inactive</b-badge>
				</span>
				<span v-else>
					<b-badge variant="success" class="badgeFont">Active</b-badge>
				</span>
			</b-form-group>

			<!-- Destination -->
			<b-form-group label="Destination" label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
				{{ destinationCompany }}
				&nbsp;
				<span v-if="selDispatch.toInactiveNode === 'true'">
					<b-badge variant="secondary" class="badgeFont">Inactive</b-badge>
				</span>
				<span v-else>
					<b-badge variant="success" class="badgeFont">Active</b-badge>
				</span>
			</b-form-group>

			<!-- Driver -->
			<b-form-group label="Driver" label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
				{{ driverName }}
				&nbsp;
				<span v-if="isDriverActive">
					<b-badge variant="success" class="badgeFont">Active</b-badge>
				</span>
				<span v-else>
					<b-badge variant="secondary" class="badgeFont">Inactive</b-badge>
				</span>
			</b-form-group>

			<!-- When there is an asset limit exceeded on the destination location -->
			<div class="info my-4 px-2" v-show="confirmAdditionalTxt.length > 0">
				<i class="icon-exclamation"></i> <span v-html="confirmAdditionalTxt"></span>
			</div>

			<!-- When Driver is Active -->
			<div v-if="errorMsg.length > 0" class="confirm-message mb-4">
				Since dispatch <b>{{ selDispatch.dispatchNo }}</b> has an active driver, the following issue(s) must be
				addressed in Ayun! Transport App:
				<br />
				<br />
				<i class="icon-info"></i> &nbsp;{{ errorMsg }}

				<div class="instruction mt-2">
					Contact the driver assigned to this dispatch to proceed.
				</div>
			</div>
			<div v-else class="confirm-message mb-2">
				Are you sure you want to deploy <b class="numFont">{{ selDispatch.dispatchNo }}</b> ?
			</div>
		</div>
	</b-modal>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// API & DAO
import dispatchApi from '@/api/dispatchApi';
import distributionDAO from '@/database/assetPoolDistributions';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'deploy-dispatch',
	components: {
		Loading,
	},
	data() {
		return {
			selDispatch: {},
			loggedUser: this.$store.getters.loggedUser,

			hasError: false,
			errorMsg: '',
			confirmAdditionalTxt: '',
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Deploy Dispatch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		sourceCompany() {
			let source = this.selDispatch && this.selDispatch.source ? this.selDispatch.source : {};
			return source && source.company ? source.company : '-';
		},
		destinationCompany() {
			let destination = this.selDispatch && this.selDispatch.destination ? this.selDispatch.destination : {};
			return destination && destination.company ? destination.company : '-';
		},
		driverName() {
			let driver = this.selDispatch && this.selDispatch.driver ? this.selDispatch.driver : {};
			return driver && driver.name ? driver.name : '-';
		},
		isDriverActive() {
			let driver = this.selDispatch && this.selDispatch.driver ? this.selDispatch.driver : {};
			return driver && driver.userId && driver.userId.length > 0 ? true : false;
		}
	},
	mounted() {
		EventBus.$on('onDeployDispatch', async (selDispatch) => {
			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				this.hasError = this.validateProofOfDispatch(selDispatch);
				this.confirmAdditionalTxt = await this.getConfirmAdditionalTxt();
			}
		});
	},
	methods: {
		async getConfirmAdditionalTxt() {
			let message = '';
			let exceededLimits = await this.getExceededLimits(this.selDispatch);

			if (!_.isEmpty(exceededLimits)) {
				message += 'This dispatch will <b>exceed</b> the destination limit on the following asset types: \n';
				
				for(let i = 0 ; i < exceededLimits.length ; i++) {
					let limit = exceededLimits[i];
					message += limit.assetType + ' - <b>' + limit.exceededLimit + '</b>, ';
				}
				message = message.substring(0, message.length - 2);
			}

			return message;
		},
		async getExceededLimits(dispatch) {
			let exceededLimits = [];

			let dispatchAssets = dispatch.assets;

			let source = dispatch && dispatch.source ? dispatch.source : {};
			let sourceCompanyId = source.companyId? source.companyId : '';
			let destination = dispatch && dispatch.destination ? dispatch.destination : {};
			let destinationCompanyId = destination.companyId ? destination.companyId : '';
			let destinationLocId = destination.storageLocationId ? destination.storageLocationId : '';
			
			let distributionsObj = await distributionDAO.getDistributions(sourceCompanyId, destinationCompanyId, destinationLocId);

			_.forEach(dispatchAssets, dispatchAsset => {
				let assetTypeId = dispatchAsset.assetTypeId;

				let distribution = _.find(distributionsObj, o => {
					return o.assetTypeId === assetTypeId;
				});

				if (distribution && !_.isEmpty(distribution)) {
					let expectedQuantity = dispatchAsset.expectedQuantity;
					let estimateTotal = distribution.total + expectedQuantity;
					let assetLimit = distribution.assetLimit ? distribution.assetLimit : 0;
					let exceededLimit = assetLimit > 0 ? estimateTotal - assetLimit : 0;
					
					if (exceededLimit > 0) {
						exceededLimits.push({
							assetType: dispatchAsset.assetType,
							exceededLimit: exceededLimit
						});
					}
				}
			});

			return exceededLimits;
		},
		validateProofOfDispatch(dispatch) {
			let isValid = false;

			const isFromInactiveSource = DispatchUtil.isFromInactiveSource(dispatch);
			const isActiveDriver = DispatchUtil.isActiveTransportDriver(dispatch);
			const proofOfDispatch = dispatch.proofOfDispatch;

			if (isFromInactiveSource && isActiveDriver) {
				if (!proofOfDispatch) {
					this.errorMsg = 'Dispatch does not contain proof of dispatch.';
					isValid = true;
				} else if (!proofOfDispatch.dispatcherName || proofOfDispatch.dispatcherName.length === 0) {
					this.errorMsg = 'Dispatch does not contain dispatcher\'s name.';
					isValid = true;
				}
			}

			return isValid;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			if (this.hasError) {
				this.$toaster.warning(this.errorMsg);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Require the dispatch to have at least 1 selected asset before deployment
			if (!this.selDispatch.assets || this.selDispatch.assets.length === 0) {
				this.$toaster.warning(`Dispatch "${this.selDispatch.dispatchNo}" should have at least 1 asset before deployment.`);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		async handleSubmit() {
			// update fields
			this.selDispatch.dateDeployed = DateUtil.getCurrentTimestamp();
			this.selDispatch.deployedBy = this.loggedUser.id;

			let dispatchNo = this.selDispatch.dispatchNo;

			try {
				let { data } = await dispatchApi.deployDispatch(
					this.selDispatch,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.$toaster.success(`Dispatch "${dispatchNo}" was deployed successfully.`);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseSaveDispatch', data.dispatch);
				} else {
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				this.$toaster.error(`Error deploying dispatch ${dispatchNo}. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}
	},
	beforeDestroy() {
		EventBus.$off('onDeployDispatch');
	},
};
</script>

<style scoped>
.instruction {
	font-size: 10px !important;
	color: #008036 !important;
}

.badgeFont {
	font-size: 12px;
}
</style>
