<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-truck"></i> Dispatch</b-card-title>
			<b-card-sub-title>Handles the outgoing dispatches for all companies</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>

					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From" description="Reference to the Date Created">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To" description="Reference to the Date Created">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Source Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - " :options="allSourceCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.sourceCompany"
												v-validate="{ 'selectRequired': sourceIsRequired }">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Destination Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="allDestinationCompanyOptions"
												:reduce="(company) => company.value"
												v-model="filterBy.destinationCompany">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - " :options="allAssetTypesOptions"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Plate No./Conduction No.">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - " :options="plateNoOptions"
												:reduce="(company) => company.value" v-model="filterBy.plateNo">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Dispatch No."
											description="NOTE: Input the exact Dispatch No. to search">
											<b-form-input id="dispatchNo" name="Dispatch No" type="search"
												class="numFont" v-model="filterBy.dispatchNo"
												v-validate="getValidationParam(false, /^[A-Z]{2}-[0-9]{6}-[0-9]{1,4}$/)"
												placeholder="XX-YYMMMDD-000" autocomplete="off" />
											<span v-show="errors.has('Dispatch No')" class="help-block">
												{{ errors.first('Dispatch No') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown id="dispatch-select-actions" text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item @click="addDispatch" v-show="!isViewer">
								Add Dispatch
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Dispatches in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Dispatches to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="dispatchesTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(dispatchNo)="row">
						<span class="numFont">
							<strong>{{ row.item.dispatchNo }}</strong>
						</span>
					</template>

					<template v-slot:cell(dateDeployed)="row">
						{{ row.item.dateDeployed ? getDisplayDateTime(row.item.dateDeployed) : '-' }}
					</template>

					<template v-slot:cell(dateReceived)="row">
						{{ row.item.dateReceived ? getDisplayDateTime(row.item.dateReceived) : '-' }}
					</template>

					<template v-slot:cell(source)="row">
						<span class="company-display">
							{{ row.item.source.company }}
						</span>
						<div class="location-display">
							({{ row.item.source.storageLocation }})
						</div>
					</template>
					<template v-slot:cell(destination)="row">
						<span class="company-display">
							{{ row.item.destination.company }}
						</span>
						<div class="location-display">
							({{ row.item.destination.storageLocation }})
						</div>
					</template>
					<template v-slot:cell(trucker)="row">
						<span class="company-display">
							{{ row.item.transportation.plateNo }}
						</span>
						<div class="location-display">
							({{ row.item.driver.name }})
						</div>
					</template>

					<template v-slot:cell(dispatched)="row">
						<span class="numFont">
							{{ row.item["Dispatched"] }}
						</span>
					</template>

					<template v-slot:cell(received)="row">
						<span class="numFont">
							{{ row.item["Received"] }}
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<DispatchRowStatus :row="row" />
					</template>

					<template v-slot:cell(actions)="row">
						<DispatchRowActions :row="row" parentComponent="Dispatch" :allCompaniesObj="allCompaniesObj" />
					</template>

					<template slot="row-details" slot-scope="row">
						<DispatchDetailsView :row="row" :allUsersObj="allUsersObj" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<DeployDispatch />
		<CancelDispatch />
		<DispatchLocationView />
		<PrintDispatchSummary :allStorageLocationsObj="allStorageLocationsObj" :allUsersObj="allUsersObj" />
		<ImageViewDialog />
		<ClassifyDispatchAssets :allAssetTypesObj="allAssetTypesObj" />
		<MarkClassificationAsDone />
	</div>
</template>

<script>
// Components
import DispatchRowStatus from '@/views/transactions/dispatch/DispatchRowStatus';
import DispatchRowActions from '@/views/transactions/dispatch/DispatchRowActions';
import DispatchDetailsView from '@/views/transactions/dispatch/DispatchDetailsView';
import DeployDispatch from '@/views/transactions/dispatch/DeployDispatch';
import CancelDispatch from '@/views/transactions/dispatch/CancelDispatch';
import PrintDispatchSummary from '@/views/transactions/dispatch/PrintDispatchSummary';
import DispatchLocationView from '@/views/transactions/dispatch/DispatchLocationView';
import ImageViewDialog from '@/views/transactions/common/ImageViewDialog';
import MarkClassificationAsDone from '@/views/transactions/classification/MarkClassificationAsDone';
import ClassifyDispatchAssets from '@/views/transactions/classification/ClassifyDispatchAssets';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API & DAO
import assetTypeDAO from '@/database/assetTypes';
import dispatchApi from '@/api/dispatchApi';
import dispatchDAO from '@/database/dispatches';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'dispatch',
	components: {
		DispatchRowStatus,
		DispatchRowActions,
		DispatchDetailsView,
		DeployDispatch,
		CancelDispatch,
		PrintDispatchSummary,
		DispatchLocationView,
		ImageViewDialog,
		MarkClassificationAsDone,
		ClassifyDispatchAssets,
		Loading,
		JsonExcel,
		truncate
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dispatchNo',
					label: 'Dispatch No.',
					sortable: true,
				},
				{
					key: 'Date Deployed',
					sortable: true,
				},
				{
					key: 'Date Received',
					sortable: true,
				},
				{
					key: 'source',
					label: 'Source',
					sortable: true,
				},
				{
					key: 'destination',
					label: 'Destination',
					sortable: true,
				},
				{
					key: 'trucker',
					label: 'Trucker',
					sortable: true,
				},
				{
					key: 'Dispatched',
					label: 'Dispatched',
					sortable: true,
				},
				{
					key: 'Received',
					label: 'Received',
					sortable: true,
				},
				{
					key: 'status',
					class: 'text-center'
				},
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			dateFormatOptions: { ...config.dateFormatOptions },

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: { ...config.companyDefaultValue },
				destinationCompany: { ...config.companyDefaultValue },
				status: null,
				dispatchNo: '',
				assetType: { ...config.assetTypeDefaultValue },
				plateNo: { ...config.transportationDefaultValue },
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: { ...config.companyDefaultValue },
				destinationCompany: { ...config.companyDefaultValue },
				status: null,
				dispatchNo: '',
				assetType: { ...config.assetTypeDefaultValue },
				plateNo: { ...config.transportationDefaultValue },
			},
			prevFilterBy: {},

			// Dispatch Params
			params: {},

			allSourceCompanyOptions: [],
			allDestinationCompanyOptions: [],
			allAssetTypesOptions: [],
			statusOptions: config.dispatchStatus,
			plateNoOptions: [],

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},
			allClientAccountsObj: {},
			allCompanyAssetPoolsObj: {},
			allDispatchesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isManager: this.$store.getters.isManager,
			isSupervisor: this.$store.getters.isSupervisor,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,

			// Listener
			dispatchListener: null,
		};
	},
	watch: {
		"filterBy.sourceCompany": function (newVal) {
			if (!this.isSuperAdmin && newVal && newVal.id !== null) {
				let companyId = newVal.id;
				let parentCompanyId = newVal.parentCompanyId;

				if (companyId !== this.loggedUserCompany.id && parentCompanyId !== this.loggedUserCompany.id) {
					let companiesObj = _.filter(this.allCompaniesObj, o => {
						return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
					});
					this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(companiesObj);
				} else {
					this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				}
			} else {
				this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			}
		},
		"filterBy.destinationCompany": function (newVal) {
			if (!this.isSuperAdmin && newVal && newVal.id !== null) {
				let companyId = newVal.id;
				let parentCompanyId = newVal.parentCompanyId;

				if (companyId !== this.loggedUserCompany.id && parentCompanyId !== this.loggedUserCompany.id) {
					let companiesObj = _.filter(this.allCompaniesObj, o => {
						return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
					});
					this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(companiesObj);
				} else {
					this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				}
			} else {
				this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			}
		},
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		sourceIsRequired() {
			return !this.isSuperAdmin
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Dispatch No.': 'dispatchNo',
				'Date Deployed': 'Date Deployed',
				'Date Received': 'Date Received',
				Source: 'Source',
				Destination: 'Destination',
				Trucker: 'Trucker',
				'Dispatched': 'Dispatched',
				'Received': 'Received',
				Status: 'status',
				'Client Account': 'clientAccountNo',
				'Is Delayed Transfer?': 'Is Delayed Transfer?',
				'Delayed Transfer Days': 'Delayed Transfer Days',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
				'Date Cancelled': 'Date Cancelled',
				'Date Rejected': 'Date Rejected',
				'Created By': 'createdBy',
				'Deployed By': 'deployedBy',
				'Received By': 'receivedBy',
				'Updated By': 'updatedBy',
				'Cancelled By': 'cancelledBy',
				'Rejected By': 'rejectedBy',
				'Dispatch ID': 'dispatchId',
				'Truck Company': 'transportation.company',
				Remarks: 'notes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Dispatch-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	created() {
		// Set Default Source Company
		if (!this.isSuperAdmin) {
			this.defaultFilterBy.sourceCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
		}

		// Set Default Date Range
		this.defaultFilterBy.dateFrom = this.dateFrom;
		this.defaultFilterBy.dateTo = this.dateTo;
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isRetailer) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				await this.updateParams();

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveDispatch', (dispatchObj) => {
			this.updateTable(dispatchObj);
		});
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},

		async listenerCallback(type, dispatch) {
			if ((!this.allDispatchesObj[dispatch.id] && type === "added") || type === "modified") {
				this.allDispatchesObj[dispatch.id] = dispatch;
				this.filterDispatches(this.allDispatchesObj);
				await this.updateAssetTypes(dispatch.assets);
			}
		},
		async updateAssetTypes(assets) {
			let assetTypeIds = _.map(assets, 'assetTypeId');
			let allAssetTypeIds = _.map(this.allAssetTypesObj, 'id');
			let nonExistentAssetTypeIds = _.difference(assetTypeIds, allAssetTypeIds);

			if (nonExistentAssetTypeIds && !_.isEmpty(nonExistentAssetTypeIds)) {
				let assetTypesObjResults = await assetTypeDAO.getAssetTypesByIds(nonExistentAssetTypeIds);
				let assetTypesObj = assetTypesObjResults[0];

				this.$store.dispatch('updateAllAssetTypes', assetTypesObj);
				this.allAssetTypesObj = { ...this.allAssetTypesObj, ...assetTypesObj };
			}
		},

		async updateParams() {
			// init parameters
			this.params = this.$store.getters.dispatchParams;

			if (!_.isEmpty(this.params) && this.params.filterBy) {
				this.filterBy = { ...this.params.filterBy };
			} else {
				// reset filter to default
				this.filterBy = { ...this.defaultFilterBy };
			}

			if (!_.isEmpty(this.params) && this.hasCompleteParams(this.params)) {
				this.allCompaniesObj = this.params.allCompaniesObj;
				this.allConnectedCompaniesObj = this.params.allConnectedCompaniesObj;
				this.allStorageLocationsObj = this.params.allStorageLocationsObj;
				this.allConnectionsObj = this.params.allConnectionsObj;
				this.allUsersObj = this.params.allUsersObj;
				this.allTransportationsObj = this.params.allTransportationsObj;
				this.allAssetTypesObj = this.params.allAssetTypesObj;
				this.allClientAccountsObj = this.params.allClientAccountsObj;
				this.allCompanyAssetPoolsObj = this.params.allCompanyAssetPoolsObj;
			} else {
				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = this.isSuperAdmin ? { ...this.allCompaniesObj } : { ...this.$store.getters.connectedCompanies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations };
				this.allConnectionsObj = { ...this.$store.getters.connections };
				this.allUsersObj = { ...this.$store.getters.users, ...this.$store.getters.connectedUsers };
				this.allTransportationsObj = { ...this.$store.getters.transportations };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allClientAccountsObj = { ...this.$store.getters.clientAccounts };
				this.allCompanyAssetPoolsObj = { ...this.$store.getters.companyAssetPools };
			}

			// Create Source and Destination Company Options
			if (this.isSuperAdmin) {
				this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			} else {
				this.allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };

				this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allConnectedCompaniesObj);
			}

			// Create Transportations Options
			this.plateNoOptions = DropDownItemsUtil.retrievePlateNos(this.allTransportationsObj);

			// Create Asset Type Options
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			await this.resetFilters();
			await this.retrieveData();
		},
		hasCompleteParams(params) {
			if (
				!params.filterBy ||
				!params.allCompaniesObj ||
				!params.allConnectedCompaniesObj ||
				!params.allStorageLocationsObj ||
				!params.allConnectionsObj ||
				!params.allUsersObj ||
				!params.allTransportationsObj ||
				!params.allAssetTypesObj ||
				!params.allClientAccountsObj ||
				!params.allCompanyAssetPoolsObj
			) {
				return false;
			}

			return params.fromAddDispatch || params.fromEditDispatch;
		},
		updateTable(dispatchObj) {
			if (_.isEmpty(dispatchObj)) {
				return;
			}
			this.allDispatchesObj[dispatchObj.id] = dispatchObj;
			this.filterDispatches(this.allDispatchesObj);
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			} else if (!this.isSuperAdmin && !this.filterBy.sourceCompany.id) {
				this.$toaster.warning('Source Company is required.');
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				await this.retrieveData();
			}
		},
		getFilterParam() {
			let filter = { ...this.filterBy };
			filter.source = 'Dispatch';
			filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
			filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));
			filter.companyId = this.loggedUserCompany.id;
			return filter;
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = this.getFilterParam();

				if (this.isSuperAdmin) {
					const { data } = await dispatchApi.getDispatches(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allDispatchesObj = data.dispatches;
				} else {
					const { data } = await dispatchApi.getDispatches(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allDispatchesObj = data.dispatches;
				}

				this.filterDispatches(this.allDispatchesObj);

				// Update listener
				this.dispatchListener = dispatchDAO.getDispatchListener(filter, this.listenerCallback);
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		filterDispatches(allDispatchesObj) {
			let filteredObjs = { ...allDispatchesObj };

			let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
			let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

			_.forEach(allDispatchesObj, (dispatch, id) => {
				this.filterBySourceAndDestination(filteredObjs, dispatch, id);

				if (dispatch.dateCreated < fromTimestamp && dispatch.dateCreated > toTimestamp) {
					delete filteredObjs[id];
				}

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== dispatch.status) {
					delete filteredObjs[id];
				}

				let dispatchNo = this.filterBy.dispatchNo;
				if (dispatchNo && dispatchNo.length > 0 && dispatchNo !== dispatch.dispatchNo) {
					delete filteredObjs[id];
				}

				let plateNo = this.filterBy.plateNo;
				if (plateNo && plateNo.length > 0 && plateNo !== dispatch.transportation.plateNo) {
					delete filteredObjs[id];
				}

				let assetType = this.filterBy.assetType;
				if (assetType && assetType.id && !DispatchUtil.hasAssetType(dispatch.assets, assetType.id)) {
					delete filteredObjs[id];
				}
			});

			this.processDispatches(filteredObjs);
		},
		filterBySourceAndDestination(filteredObjs, dispatch, id) {
			let sourceCompanyId = dispatch.source.companyId;
			let destinationCompanyId = dispatch.destination.companyId;

			let companiesObj;
			if (this.isSuperAdmin) {
				companiesObj = { ...this.allCompaniesObj };
			} else {
				companiesObj = _.filter(this.allCompaniesObj, o => {
					return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
				});
			}

			if (!UserUtil.hasCompanyAccess(companiesObj, sourceCompanyId)
				&& !UserUtil.hasCompanyAccess(companiesObj, destinationCompanyId)) {
				delete filteredObjs[id];
			}
		},
		processDispatches(dispatches) {
			this.items = Object.values(dispatches);

			this.items.forEach((item) => {
				// Parse source and destination display
				item['Source'] = DispatchUtil.getCompanyLocationDisplay(item.source);
				item['Destination'] = DispatchUtil.getCompanyLocationDisplay(item.destination);

				// Delayed Transfer
				item['Is Delayed Transfer?'] = item.isDelayedTransfer ? 'YES' : 'NO';
				item['Delayed Transfer Days'] = item.delayedTransferDays ? item.delayedTransferDays : 0;

				// Transportation
				item['Trucker'] = item.transportation.plateNo + ' (' + item.driver.name + ')';
				item['Truck Assistant'] = item.driver.assistants;

				// Parse Asset Counts
				item['Dispatched'] = DispatchUtil.getTotalExpectedQuantity(item.assets);
				item['Received'] = DispatchUtil.getTotalActualQuantity(item.assets);

				// Parse timestamps
				item['Date Created'] = this.getDisplayDateTime(item.dateCreated);
				item['Date Updated'] = this.getDisplayDateTime(item.dateUpdated);
				item['Date Deployed'] = this.getDisplayDateTime(item.dateDeployed);
				item['Date Received'] = this.getDisplayDateTime(item.dateReceived);
				item['Date Cancelled'] = this.getDisplayDateTime(item.dateCancelled);
				item['Date Rejected'] = this.getDisplayDateTime(item.dateRejected);

				// remove show details
				delete item._showDetails;
			});
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();
			this.totalRows = this.items.length;

			this.$store.dispatch('updateAllDispatches', this.allDispatchesObj);

			// refresh table
			if (this.$refs.dispatchesTable) {
				this.$refs.dispatchesTable.refresh();
			}

			this.updateDispatchParams();
		},

		addDispatch() {
			this.updateDispatchParams();
			this.$store.dispatch('setCurrentDispatch', {});

			// redirect to Add Dispatch page
			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-add-dispatch' });
			} else {
				this.$router.push({ path: '/add-dispatch' });
			}
		},

		updateDispatchParams() {
			this.params = {
				allCompaniesObj: this.allCompaniesObj,
				allConnectedCompaniesObj: this.allConnectedCompaniesObj,
				allStorageLocationsObj: this.allStorageLocationsObj,
				allConnectionsObj: this.allConnectionsObj,
				allUsersObj: this.allUsersObj,
				allTransportationsObj: this.allTransportationsObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allClientAccountsObj: this.allClientAccountsObj,
				allCompanyAssetPoolsObj: this.allCompanyAssetPoolsObj,
				filterBy: this.filterBy,
			};

			this.$store.dispatch('setDispatchParams', this.params);
		},

		// UTILS
		getDisplayDateTime(date) {
			if (date) {
				return DateUtil.getFormattedDateWithTime(date);
			}
			return "-";
		},
		breakRemarks(remarks, length) {
			return remarks.length > length ? remarks.replace(new RegExp(`([^\\s]{${length}})`, 'g'), '$1<br>') : remarks;
		}
	},

	beforeUnmount() {
		if (this.dispatchListener != null) {
			// Stop listening to changes
			this.dispatchListener();
		}
	},

	beforeDestroy() {
		EventBus.$off('onCloseSaveDispatch');
	},
};
</script>